import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Image, Typography, Progress, Divider, List, Modal, Tabs} from 'antd';
import {VisibilityContext} from 'react-horizontal-scrolling-menu';
//@ts-ignore

import moment from 'moment';

import './reportPage.css';

import AttendanceCircle from '../../assets/AttendanceCircle.svg';

import {DemoLine} from './Graph';
import {MonthlyReport, Statistic, Student, Syllable, User} from '../../lib';
import {StatisticRepository} from '../../lib/repositories/StatisticRepository';
import {Loader} from '../../components';
import useDrag from './useDrag';
import {LeftArrow, RightArrow} from './Arrows';
import {isLoadingRequest} from '../../redux/actions';
import FooterBar from '../../components/Footerbar';
import AttendanceDashboardModal from './AttendanceDashboardModal';
import ResponsiveAppBar from '../../components/Appbar';
import ReportsHeader from '../../components/ReportsHeader';

const statisticRepository = new StatisticRepository();

export const ReportPage = () => {
  const {dragging} = useDrag();
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedItemData, setSelectedItemData] = useState(null);
  const [performaceDailyGraph, setPerformaceDailyGraph] = useState<any>([]);
  const [months, setMonths] = useState<any>([]);
  const videoPlayerRef: any = useRef();
  const dispatch = useDispatch();

  const {Text} = Typography;

  const selectedStudent: Student = useSelector(
    (state: any) => state.student?.selectedStudent,
  );

  const [isVideoModalVisible, setIsVideoModalVisible] =
    useState<boolean>(false);

  const [selectedSyllable, setSelectedSyllable] = useState<any>();

  const [selectedMonth, setSelectedMonth] = useState<any>([]);

  const [report, setReport] = useState<Statistic>();
  const [currentMonthlyReport, setCurrentMonthlyReport] =
    useState<MonthlyReport>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [performanceData, setPerformanceData] = useState<any>([]);

  const [sortedDaysList, setSortedDaysList] = useState<any>();

  // const [syllableVideos, setSyllableVideos] = useState<Array<Syllable>>([]);

  useEffect(() => {
    dispatch(isLoadingRequest(true));
    setIsLoading(true);
    statisticRepository
      .generateFullReportAsString(selectedStudent.id)
      .then((e) => {
        console.log('generateFullReport done->', e);
        console.log('getFullReport start');
        statisticRepository
          .getFullReport(selectedStudent.statistic?.id!)
          .then((fullReport) => {
            console.log('fullReport fetched->', fullReport);
            let sortedMonthlyReports = fullReport?.monthlyReports.sort(
              (a: any, b: any) => {
                //@ts-ignore
                return new Date(a.month) - new Date(b.month);
              },
            );

            console.log('sortedMonthlyReports', sortedMonthlyReports);

            let performaceGraph: {Score: number; Month: string}[] = [];
            let count = 0;

            while (count < sortedMonthlyReports.length && count < 6) {
              const mr = sortedMonthlyReports[count];
              performaceGraph?.push({
                Score: mr?.average ? mr.average : 0,
                Month: moment(mr.month).format('MMM YY'),
              });
              count++;
            }

            // console.log('fullReport.monthlyReports', fullReport.monthlyReports);
            // console.log('performaceGraph', performaceGraph);

            const parsedMonthlyReports = fullReport.monthlyReports?.map(
              (rep: any) => {
                let monthlyReportInput: any = {...rep};

                for (let dayIndex = 1; dayIndex <= 31; dayIndex++) {
                  monthlyReportInput[`d${dayIndex}`] = JSON.parse(
                    rep[`d${dayIndex}`],
                  );
                }

                return monthlyReportInput;
              },
            );

            console.log('parsedMonthlyReports', parsedMonthlyReports);

            let currentMonthReport: MonthlyReport = parsedMonthlyReports?.find(
              (mr: MonthlyReport) =>
                moment(mr.month).format('YYYY-MM') === selectedMonth.month,
            );

            let performaceDailyGr: {Score: number; Month: string}[] = [];

            const isCurrentReportOld = moment(
              currentMonthReport?.month,
            ).isBefore(moment(), 'M');
            console.log('isCurrentReportOld', isCurrentReportOld);
            const endOfloop = isCurrentReportOld
              ? moment(currentMonthReport?.month).clone().endOf('month').date()
              : moment().date() - 1;

            for (let i = 1; i <= endOfloop; i++) {
              if (currentMonthReport) {
                //@ts-ignore
                console.log('DAYYYYY', currentMonthReport['d' + i]);

                performaceDailyGr?.push({
                  //@ts-ignore
                  Score: currentMonthReport['d' + i]?.average
                    ? //@ts-ignore
                      currentMonthReport['d' + i].average?.toFixed(2)
                    : 0,
                  Month: moment(currentMonthReport?.month)
                    .set('date', i)
                    .format('ddd-DD'),
                });
              }
            }
            console.log('performaceDailyGr', performaceDailyGr);
            try {
              fullReport.phoneticsAsString = JSON.parse(
                fullReport.phoneticsAsString,
              );
            } catch (e) {
              console.log('Inside parsing catch->', e);
            }

            try {
              fullReport.syllablesAsString = JSON.parse(
                fullReport.syllablesAsString,
              );
            } catch (e) {
              console.log('Inside parsing catch->', e);
            }
            setPerformaceDailyGraph(performaceDailyGr);
            setPerformanceData(performaceGraph);
            console.log('currentMonthlyReport->', currentMonthReport);
            setCurrentMonthlyReport(currentMonthReport);

            setReport(fullReport);
            getSortedDaysList();
            dispatch(isLoadingRequest(false));
            // setIsLoading(false);
            // let first20Syllable = fullReport.syllablesAsString
            //   ?.sort(
            //     //@ts-ignore
            //     (a: any, b: any) => b.mistakes - a.mistakes,
            //   )
            //   ?.slice(0, 20);

            // let first20Letters = first20Syllable.map((s: any) => s.letters);

            // statisticRepository
            //   .getSyllablesByLetters(first20Letters)
            //   .then((first20SyllableVideo: any) => {
            //     setSyllableVideos(first20SyllableVideo);
            //   });
          })
          .catch((error) => {
            console.log('error 1->', error);
            alert(`Error in getting your report. Please contact us:${error}`);
          });
      })
      .catch((error) => {
        console.log('error 2->', error);
        // dispatch(isLoadingRequest(false));

        alert(`Error in generating your report. Please contact us:${error}`);
      })
      .finally(() => {
        dispatch(isLoadingRequest(false));
        setIsLoading(false);
      });

    return () => {
      dispatch(isLoadingRequest(false));
    };
  }, [selectedMonth]);

  useEffect(() => {
    getSortedDaysList();
  }, [currentMonthlyReport]);

  const getSortedDaysList = () => {
    let list = {};

    for (let i = 1; i <= 31; i++) {
      if (currentMonthlyReport) {
        //@ts-ignore
        list['d' + i] = currentMonthlyReport['d' + i];
      }
    }
    console.log('sorted->', list);
    setSortedDaysList(list);
  };

  const attendanceGraph = () => {
    const handleItemClick = (itemData: any) => {
      setSelectedItemData(itemData); // Set the selected item's data
      setModalOpen(true); // Open the modal
    };
    return Object.entries(sortedDaysList).map(function (
      [key, value]: any,
      index,
    ) {
      return (
        <div
          style={{
            marginBottom: 10,
            alignSelf: 'self-end',
          }}>
          <div
            style={{
              borderBottom: '1px solid #999999',
              paddingBottom: 10,
              display: 'flex',
              flexDirection: 'column-reverse',
              alignItems: 'center',
            }}>
            {[...new Array(sortedDaysList[key].numOfTotalAns)].map(
              (_, index) => {
                return index < 5 ? (
                  <Image
                    key={index}
                    wrapperStyle={{display: 'block'}}
                    width={8}
                    preview={false}
                    src={AttendanceCircle}
                  />
                ) : (
                  index === 5 && (
                    <Text
                      style={{
                        fontFamily: 'Roboto',
                        color: '#999999',
                        fontSize: 13,
                      }}>
                      +{sortedDaysList[key].numOfTotalAns - 5}
                    </Text>
                  )
                );
              },
            )}
          </div>

          <Text
            onClick={() => handleItemClick({...value, day: index + 1})}
            className="attendance-graph-index">
            {index + 1}
          </Text>
        </div>
      );
    });
  };
  const renderMonthlyAttendance = () => {
    return (
      <div
        style={{
          marginTop: 30,
          overflowY: 'auto',
        }}>
        <div style={{marginBottom: 30}}>
          <Text
            style={{
              fontFamily: 'Roboto',
              color: '#999999',
              display: 'inline-block',
              fontSize: 20,
            }}>
            Attendance This Month {moment().format('MMMM')}
          </Text>
          <div style={{display: 'inline-block', float: 'right'}}>
            <Text
              style={{
                fontFamily: 'Roboto',
                color: '#C3E56E',
                marginRight: 20,
                fontSize: 20,
              }}>
              {currentMonthlyReport?.attempts &&
              currentMonthlyReport?.attempts !== 0
                ? `${currentMonthlyReport?.attempts} Recorded Exercises`
                : 'No Recorded Exercises for this month yet.'}
            </Text>
            <Text
              style={{
                fontFamily: 'Roboto',
                color: '#F2406A',
                fontSize: 20,
              }}>
              {currentMonthlyReport?.absenceDays
                ? `${currentMonthlyReport?.absenceDays ?? 0} Absence Days`
                : 'No attendance this month for this student'}{' '}
            </Text>
          </div>
        </div>
        <div
          style={{
            display: 'grid',
            gridAutoFlow: 'column',
            //@ts-ignore
            textAlign: '-webkit-center',
            minHeight: 200,
          }}>
          {attendanceGraph()}
          <AttendanceDashboardModal
            isOpen={isModalOpen}
            onClose={() => setModalOpen(false)}
            info={selectedItemData}
          />
        </div>
      </div>
    );
  };

  const RenderDailyPerformance = () => {
    return (
      <div
        className="renderDailyPerformance-grid-columns"
        style={{
          display: 'grid',
          marginBottom: 60,
        }}>
        <div>
          <Text
            style={{
              display: 'block',
              fontFamily: 'Roboto',
              color: '#999999',
              fontSize: 18,
              marginBottom: 30,
              marginTop: 30,
              textDecoration: 'underline',
            }}>
            Daily Performance
          </Text>
          {/*@ts-ignore*/}
          <DemoLine data={performaceDailyGraph} />
        </div>
      </div>
    );
  };

  const renderPerformance = () => {
    return (
      <div
        className="renderPerformance-grid-columns"
        style={{
          display: 'grid',
        }}>
        <div>
          <Text
            style={{
              display: 'block',
              fontFamily: 'Roboto',
              color: '#999999',
              fontSize: 18,
              marginBottom: 30,
              marginTop: 30,
              fontWeight: 'bold',
            }}>
            Current Month Performance
          </Text>
          <Progress
            width={300}
            className="flex-algin-center"
            format={(percent) => (
              <div>
                <Text
                  style={{
                    display: 'block',
                    marginTop: 10,
                    fontFamily: 'Roboto',
                    color: '#40B2F2',
                    fontSize: 40,
                  }}>
                  {currentMonthlyReport?.average
                    ? currentMonthlyReport?.average.toFixed(2)
                    : 0}
                  %
                </Text>

                <Text
                  style={{
                    display: 'block',
                    fontFamily: 'Roboto',
                    color: '#000000',
                    fontSize: 13,
                    fontWeight: 'bold',
                  }}>
                  Native American Accent
                </Text>
                <Text
                  style={{
                    display: 'block',
                    marginTop: 10,
                    fontFamily: 'Roboto',
                    color: '#999999',
                    fontSize: 13,
                  }}>
                  {moment().format('MMMM')}
                </Text>
              </div>
            )}
            type="circle"
            percent={
              currentMonthlyReport?.average ? currentMonthlyReport?.average : 0
            }
            strokeWidth={3}
            strokeColor={{'0%': '#6EBEF2', '100%': '#C3E56F'}}
          />
        </div>
        <Divider
          className="verticalDivider"
          orientation={'center'}
          style={{height: '100%', justifySelf: 'center'}}
          type={'vertical'}
        />
        <Divider
          className="horizontalDivider"
          orientation={'center'}
          style={{height: '100%', justifySelf: 'center'}}
          type={'horizontal'}
        />

        <div>
          <Text
            style={{
              display: 'block',
              fontFamily: 'Roboto',
              color: '#999999',
              fontSize: 18,
              marginBottom: 30,
              marginTop: 30,
              fontWeight: 'bold',
            }}>
            Last 6 Months Performance
          </Text>
          {/*@ts-ignore*/}
          <DemoLine data={performanceData} />
        </div>
      </div>
    );
  };

  const renderMistakes = () => {
    return (
      <div className="renderMistakes-columns">
        <div>
          <Text
            style={{
              display: 'block',
              fontFamily: 'Roboto',
              color: '#999999',
              fontSize: 18,
              marginBottom: 30,
              marginTop: 30,
              textDecoration: 'underline',
            }}>
            Top 20 Syllables Mistakes
          </Text>
          <div className="scrollable-mistakes scrollable-element">
            {report?.syllablesAsString
              // @ts-ignore
              ?.sort((a, b) => b.mistakes - a.mistakes)
              .slice(0, 20)
              //@ts-ignore
              .map((item, index) => (
                <div
                  key={`${item.letters}+${index}`}
                  className="list-item-container">
                  <Text className="mistake-style">{item.letters}</Text>
                  <div style={{display: 'flex', flexDirection: 'column'}}>
                    <Text
                      style={{
                        fontFamily: 'Roboto',
                        color: '#000000',
                        fontSize: 18,
                      }}>
                      {item.mistakes} Mistakes
                    </Text>
                    <Text
                      style={{
                        fontFamily: 'Roboto',
                        color: '#999999',
                        fontSize: 18,
                      }}>
                      {item.attempts} Trials
                    </Text>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div>
          <Text
            style={{
              display: 'block',
              fontFamily: 'Roboto',
              color: '#999999',
              fontSize: 18,
              marginBottom: 30,
              marginTop: 30,
              textDecoration: 'underline',
            }}>
            Top 20 Phonetics Mistakes
          </Text>
          <div className="scrollable-mistakes scrollable-element">
            {report?.phoneticsAsString
              // @ts-ignore

              ?.sort((a, b) => b.mistakes - a.mistakes)
              .slice(0, 20)
              //@ts-ignore
              .map((item, index) => (
                <div
                  key={`${item.phone}+${index}`}
                  className="list-item-container">
                  <Text className="mistake-style">{item.phone}</Text>
                  <div style={{display: 'flex', flexDirection: 'column'}}>
                    <Text
                      style={{
                        fontFamily: 'Roboto',
                        color: '#000000',
                        fontSize: 18,
                      }}>
                      {item.mistakes} Mistakes
                    </Text>
                    <Text
                      style={{
                        fontFamily: 'Roboto',
                        color: '#999999',
                        fontSize: 18,
                      }}>
                      {item.attempts} Trials
                    </Text>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div style={{textAlign: 'center'}}>
        <Text
          style={{
            display: 'block',
            fontFamily: 'Roboto',
            color: '#C4C4C4',
            fontSize: 13,
          }}>
          PikaDo LLC
        </Text>
        <Text
          style={{
            display: 'block',
            fontFamily: 'Roboto',
            color: '#C4C4C4',
            fontSize: 13,
          }}>
          Suite 302, 4001 Kennett Pike, County of New Castle, Wilmington,
          Delaware 19807, USA
        </Text>
        <div>
          <a
            style={{display: 'inline-block'}}
            target="_blank"
            href={'https://pikado.io/'}>
            <Text
              style={{
                display: 'inline-block',
                fontFamily: 'Roboto',
                color: '#C4C4C4',
                fontSize: 13,
                marginRight: 20,
              }}>
              www.PikaDo.io
            </Text>
          </a>
          <a
            style={{display: 'inline-block'}}
            target="_blank"
            href={'mailto:abc@example.com'}>
            <Text
              style={{
                display: 'inline-block',
                fontFamily: 'Roboto',
                color: '#C4C4C4',
                fontSize: 13,
              }}>
              info@PikaDo.io
            </Text>
          </a>
        </div>
      </div>
    );
  };

  const handleItemClick = (item: any) => () => {
    if (dragging) {
      return false;
    }
    setSelectedSyllable(item);
    setIsVideoModalVisible(true);
  };

  const videoModal = () => {
    return (
      <Modal
        className={'exercise-modal'}
        style={{maxHeight: '70%'}}
        width={'auto'}
        centered={true}
        footer={null}
        title={selectedSyllable.letters}
        open={true}
        onOk={() => {}}
        onCancel={() => {
          setIsVideoModalVisible(false);
        }}>
        <video
          controlsList="nodownload"
          controls={true}
          onLoadedData={() => {
            videoPlayerRef?.current?.play();
          }}
          onClick={() => {
            videoPlayerRef?.current?.paused
              ? videoPlayerRef?.current?.pause()
              : videoPlayerRef?.current?.play();
          }}
          ref={videoPlayerRef}
          src={selectedSyllable?.correctSyllable.fileUri}
          poster={selectedSyllable?.correctSyllable.thumbnail}
          width={700}
        />
      </Modal>
    );
  };

  // const renderMistakesVideos = () => {
  //   return (
  //     <div
  //       style={{
  //         marginTop: 20,
  //         marginBottom: 30,
  //         position: 'relative',
  //       }}>
  //       <ScrollMenu
  //         Header={
  //           <Text
  //             style={{
  //               display: 'inline-block',
  //               fontFamily: 'Roboto',
  //               color: '#999999',
  //               fontSize: 20,
  //               marginBottom: 20,
  //             }}>
  //             Personalised videos depends on your mistakes
  //           </Text>
  //         }
  //         LeftArrow={LeftArrow}
  //         RightArrow={RightArrow}
  //         onWheel={onWheel}
  //         onMouseDown={() => dragStart}
  //         onMouseUp={() => dragStop}
  //         onMouseMove={handleDrag}>
  //         {syllableVideos?.map((item, index) => (
  //           <div onClick={handleItemClick(item)} style={{textAlign: 'center'}}>
  //             <Image
  //               style={{cursor: 'pointer'}}
  //               draggable={false}
  //               wrapperStyle={{marginRight: 10, display: 'block'}}
  //               width={200}
  //               //@ts-ignore
  //               src={item.correctSyllable?.thumbnailUri}
  //               preview={false}
  //             />
  //             <Text
  //               style={{
  //                 fontFamily: 'Roboto',
  //                 color: '#000000',
  //                 fontSize: 20,
  //                 textAlign: 'center',
  //               }}>
  //               {item.letters}
  //             </Text>
  //           </div>
  //         ))}
  //       </ScrollMenu>
  //     </div>
  //   );
  // };

  // function onWheel(apiObj: any, ev: React.WheelEvent): void {
  //   const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

  //   if (isThouchpad) {
  //     ev.stopPropagation();
  //     return;
  //   }

  //   if (ev.deltaY < 0) {
  //     apiObj.scrollNext();
  //   } else if (ev.deltaY > 0) {
  //     apiObj.scrollPrev();
  //   }
  // }

  const generateMonthsTabs = () => {
    return months.map((month: moment.MomentInput, index: any) => ({
      label: (
        <Text
          key={index}
          style={{
            display: 'block',
            fontFamily: 'Roboto',
            color: '#999999',
            fontSize: 16,
            marginBottom: 0,
          }}>
          {moment(month, 'YYYY-MM').format('MMM YYYY')}
        </Text>
      ),
      key: month,
      children: (
        <>
          {!isLoading && <ReportsHeader selectedStudent={selectedStudent} />}
          <div className="reportPageContainer">
            {report && !isLoading && (
              <>
                {/* {renderReportHeader()} */}
                {/* {syllableVideos?.length !== 0 && renderMistakesVideos()} */}
                {renderMonthlyAttendance()}
                <Divider />
                {performaceDailyGraph.length > 0 && (
                  <>
                    <RenderDailyPerformance />
                    <Divider />
                  </>
                )}
                {renderPerformance()}
                <Divider />
                {renderMistakes()}
                {report && <FooterBar />}
                {isVideoModalVisible && videoModal()}
              </>
            )}
            {isLoading && (
              <Loader title={'Analysing your data. it may take a minute.'} />
            )}
          </div>
        </>
      ),
    }));
  };

  const handleTabChange = (key: string) => {
    setSelectedMonth({
      month: key,
      index: months.indexOf(key),
    });
  };
  const fetchThePrevReportsMonthsBuyYears = () => {
    let months: string[] = [];
    const startOfYear = moment().startOf('year');
    const currentMonth = moment();
    while (startOfYear <= currentMonth) {
      months.push(startOfYear.format('YYYY-MM'));
      startOfYear.add(1, 'month');
    }
    statisticRepository
      .getFullReport(selectedStudent.statistic?.id!)
      .then((fullReport) => {
        console.log('fullReport fetched->', fullReport);
        //@ts-ignore
        if (
          fullReport &&
          fullReport.monthlyReports &&
          fullReport.monthlyReports.length > 0
        ) {
          //@ts-ignore

          fullReport.monthlyReports.map((report: any) => {
            const createdMonth = moment(report.month);
            const formattedDate = createdMonth.format('YYYY-MM');
            months.push(formattedDate);
          });
          months.sort((a, b) =>
            moment(a, 'YYYY-MM').diff(moment(b, 'YYYY-MM')),
          );

          setSelectedMonth({
            month: moment().format('YYYY-MM'),
            index: months.indexOf(moment().format('YYYY-MM')),
          });

          setMonths(months);
        } else {
          setMonths(months);
        }
      });
  };

  useEffect(() => {
    if (!selectedStudent) return;
    fetchThePrevReportsMonthsBuyYears();
  }, [selectedStudent]);
  return (
    <>
      <ResponsiveAppBar />
      <Tabs
        activeKey={selectedMonth.month}
        centered
        className="mb-0"
        onChange={handleTabChange}
        items={generateMonthsTabs()}
      />
    </>
  );
};
