import React, {
  useState,
  useRef,
  ChangeEvent,
  KeyboardEvent,
  ClipboardEvent,
  useEffect,
} from 'react';
import Swal from 'sweetalert2';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {
  fogetPasswordSubmitCode,
  sendVerificationEmailCode,
  submitVerificationCode,
} from '../../redux/actions/auth';
import {Loader} from '../../components';

const OTP: React.FC = () => {
  const isLoading = useSelector((state: any) => state.appState.isLoading);
  const location = useLocation();
  const {length, route, email, onSubmit} = location.state || {
    length: 6,
    route: '/',
  };
  const [otp, setOtp] = useState<string[]>(new Array(length).fill(''));
  const [password, setPassword] = useState<string>('');
  const [showPasswordInput, setShowPasswordInput] = useState<boolean>(false);

  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useSelector((state: any) => state.auth.user);

  useEffect(() => {
    if (auth?.email_verified === 'false') {
      //@ts-ignore
      dispatch(sendVerificationEmailCode());
    }
  }, [auth?.email_verified, dispatch]);
  useEffect(() => {
    if (
      otp.filter((v) => v !== '').length == 6 &&
      onSubmit === 'resetPassword'
    ) {
      setShowPasswordInput(true);
    }
  }, [setShowPasswordInput, otp]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const value = e.target.value;
    if (!/^\d$/.test(value) && value !== '') return;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value !== '' && index < length - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace' && index > 0 && otp[index] === '') {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handlePaste = (e: ClipboardEvent<HTMLDivElement>) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData('text').slice(0, length);
    if (!/^\d+$/.test(pasteData)) return;
    const newOtp = otp.map((_, idx) => pasteData[idx] || '');
    setOtp(newOtp);
    const filledLength = newOtp.filter((v) => v !== '').length;
    if (filledLength < length) {
      inputRefs.current[filledLength]?.focus();
    }
  };
  //TODO: Fix OTP Verification with Incorrect Input Handling
  const handleSubmit = () => {
    if (otp.filter((v) => v !== '').length < 6) {
      Swal.fire('please Enter the OTP code');
    } else if (onSubmit === 'resetPassword' && password.length < 6) {
      Swal.fire('Password must be at least 6 characters');
    } else {
      if (onSubmit === 'verifyEmail') {
        //@ts-ignore
        dispatch(submitVerificationCode('email', otp.join(''))).then((res) => {
          if (res) {
            history.push(route);
          } else {
            Swal.fire('please Valid OTP code');
          }
        });
      } else if (onSubmit === 'resetPassword') {
        //@ts-ignore
        dispatch(fogetPasswordSubmitCode(email, otp.join(''), password)).then(
          () => {
            history.push(route);
          },
        );
      }
    }
  };

  return (
    <div className="container d-flex felx-column justify-content-center align-items-center vh-100 ">
      <div className="row g-4 shadow py-4 rounded ">
        <h3 className="text-pikado-red h2 text-center">Enter the OTP</h3>

        <div
          onPaste={handlePaste}
          className="col-12 d-flex justify-content-center">
          {otp.map((value, index) => (
            <input
              key={index}
              type="text"
              maxLength={1}
              value={value}
              onChange={(e) => handleChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              ref={(ref) => (inputRefs.current[index] = ref)}
              style={{width: '40px', textAlign: 'center'}}
            />
          ))}
        </div>
        {showPasswordInput && (
          <div className="col-12 d-flex justify-content-center">
            <input
              type="password"
              placeholder="Set your new password"
              onBlur={(e) => setPassword(e.target.value)}
            />
          </div>
        )}
        <button
          onClick={handleSubmit}
          disabled={otp.filter((v) => v !== '').length < 6}
          className="w-25 mx-auto btn btn-primary">
          {showPasswordInput ? 'Submit' : 'Verify OTP'}
        </button>
      </div>
      {isLoading && <Loader title={'Loading'} />}
    </div>
  );
};

export default OTP;
