import {IAnswerRepository} from './interfaces';
import {generateClient, GraphQLResult} from 'aws-amplify/api';
import * as customQueries from '../customQueries';
import {getPartnerAuthToken} from '../../redux/actions';

const client = generateClient();

export class AnswerRepository implements IAnswerRepository {
  constructor() {}

  async createAnswer(
    studentId: string,
    questionId: string,
    audioUri: string,
    text: string,
    useEnglishAi: boolean,
    enrollmentId?: string,
    given?: string,
    homeworkId?: string,
  ): Promise<any> {
    let response: GraphQLResult<any>;
    if (enrollmentId) {
      response = await client.graphql({
        query: customQueries.createAnswerCustom,
        variables: {
          input: {
            studentId: studentId,
            questionId: questionId,
            audioUri: audioUri,
            text: text,
            useEnglishAi,
            enrollmentId: enrollmentId,
            given: given,
            homeworkId: homeworkId,
          },
        },
        authToken: getPartnerAuthToken(),
      });
    } else {
      response = await client.graphql({
        query: customQueries.createAnswerCustom,
        variables: {
          input: {
            studentId: studentId,
            questionId: questionId,
            audioUri: audioUri,
            text: text,
            useEnglishAi,
            given: given,
            homeworkId: homeworkId,
          },
        },
        authToken: getPartnerAuthToken(),
      });
    }
    return response.data.createAnswer;
  }

  async getAnswer(id: string): Promise<any> {
    let response: GraphQLResult<any> = await client.graphql({
      query: customQueries.getAnswer,
      variables: {
        id,
      },
      authToken: getPartnerAuthToken(),
    });

    console.log('response->getAnswer', response);
    return response.data.answer;
  }
}
