import React, {useState, useEffect, useRef} from 'react';
import {Typography} from 'antd';
import Button from 'react-bootstrap/Button';
import {useDispatch, useSelector} from 'react-redux';
import PlayIcon from '../../assets/startbutton.svg';

import Record from '../../assets/Record.svg';
import {TestState} from '../../redux/reducers/test';
import {doNextQuestion, setCurrentTest} from '../../redux/actions';
import {
  Enrollment,
  PikaDoCharacters,
  QUESTION_TYPE,
  StorageService,
  Student,
} from '../../lib';
import {GetUrlWithPathOutput} from 'aws-amplify/storage';
import {getFileUrlByKey} from '../../utils/Storage';
const storageService = new StorageService();
type ResultBoxType = {
  result: any;
  onNextQuestion: () => void;
  onLastQuestion: () => void;
  question?: any;
};
export const ResultBox = ({
  result,
  onNextQuestion,
  onLastQuestion,
  question,
}: ResultBoxType) => {
  const {Text, Title} = Typography;
  const test: TestState = useSelector((state: any) => state.test);
  const selectedEnrollment: Enrollment = useSelector(
    (state: any) => state.enrollment.selectedEnrollment,
  );
  const selectedStudent: Student = useSelector(
    (state: any) => state.student.selectedStudent,
  );
  console.log('test->1', test);
  const dispatch = useDispatch();
  const [selectedWord, setSelectedWord] = useState(
    result?.analysis?.text_score?.word_score_list[0]
      ? result?.analysis?.text_score?.word_score_list[0]
      : result?.analysis?.speech_score?.word_score_list[0],
  );
  const [wordUrl, setWordUrl] = useState<string>('');
  const audioRef = useRef();
  const uriRef = useRef();
  const [audioUri, setAudioUri] = useState<string>('');
  const [userTextWordList, setUserTextWordList] = useState([]);
  let baseWordUrl =
    'https://s3.eu-west-1.amazonaws.com/assets.pikado.io/words/en/us/';
  useEffect(() => {
    if (selectedWord) {
      let word = selectedWord?.word?.toLowerCase()?.replace('.', '');

      if (word) {
        const character: PikaDoCharacters = selectedStudent?.character
          ? selectedStudent?.character
          : PikaDoCharacters.Sarah;

        let thisUrl =
          baseWordUrl +
          word?.split('')[0] +
          '/' +
          character +
          '/' +
          word +
          '.mp3';

        console.log('thisUrl', thisUrl);

        fetch(thisUrl)
          // .then((response) => response.json())
          .then(async (data) => {
            if (data.status === 403 || data.status === 404) {
              const newWordUrl = await storageService.generateWordToSpeech(
                character,
                word,
              );
              console.log('newWordUrl', newWordUrl);
              const baseWord = 'https://s3.eu-west-1.amazonaws.com/';

              thisUrl = baseWord + newWordUrl;
              console.log('DSDSDSDDS-MMS---', thisUrl);
            }
            setWordUrl(thisUrl);
          })
          .catch((err) => {
            console.error('DSDSDSDDS-MMS---', err);
          })
          .finally(() => {
            setTimeout(() => {
              //@ts-ignore
              audioRef?.current?.play();
            }, 1000);
          });
      }
    }
    return () => {
      // @ts-ignore
      audioRef?.current?.pause();
    };
  }, [baseWordUrl, selectedStudent?.character, selectedWord]);

  useEffect(() => {
    const fetchAudioUri = async () => {
      try {
        console.log('result?.audioUri', result?.audioUri);
        const uri: GetUrlWithPathOutput = await getFileUrlByKey(
          'public/' + result?.audioUri,
        );

        console.log('uri', uri);
        setAudioUri(uri.url.toJSON());
      } catch (error) {
        console.error('Error fetching audio URI:', JSON.stringify(error));
      }
    };
    fetchAudioUri();
    result &&
      setUserTextWordList(
        result?.analysis?.text_score
          ? result?.analysis?.text_score?.word_score_list
          : result?.analysis?.speech_score?.word_score_list,
      );
  }, [result]);

  return (
    <div>
      <div
        className="Modal_Res_Table w-100 p-3"
        style={{
          display: 'inline-flex',
          marginTop: '20px',
          flexFlow: 'row-reverse',
        }}>
        <div
          className="Thanks_Res_Modal col-xs-12 col-sm-6 col-md-6 col-lg-6"
          style={{
            marginLeft: '20px',
            textAlign: 'left',
            marginTop: '20px',
            display: 'flex',
            flexDirection: 'column',
          }}>
          <Text
            style={{
              alignSelf: 'center',
            }}>
            {userTextWordList &&
              userTextWordList.map((word: any, index: number) => {
                return (
                  <Text
                    style={{
                      cursor: 'pointer',
                      fontWeight: word?.quality_score < 80 ? '600' : '200',
                      color: word?.quality_score < 90 ? '#FFD352' : '#000000',
                      fontSize: 19,
                      // fontWeight: 500,
                    }}
                    key={word + '_' + index}
                    onClick={() => {
                      if (selectedWord === word) {
                        //@ts-ignore
                        audioRef?.current?.play();
                      }

                      setSelectedWord(() => word);
                      // @ts-ignore
                      //result?.analysis?.text_score.word_score_list
                    }}>{`${word.word} `}</Text>
                );
              })}
          </Text>
          {test.isNextQuestion && !selectedEnrollment?.package?.isFree ? (
            <div style={{width: '100%', textAlign: 'left', marginTop: 20}}>
              <Button
                type="button"
                className="btn-record"
                data-target=".bd-example-modal-lg"
                onClick={async () => {
                  //@ts-ignore
                  await dispatch(doNextQuestion());
                  onNextQuestion();
                  console.log('inside next question');
                }}>
                <span>Next question</span>
              </Button>
            </div>
          ) : (
            (test.isLastQuestion ||
              (selectedEnrollment?.package?.isFree &&
                test.selectedQuestionIndex >= 0)) && (
              <div style={{width: '100%', textAlign: 'left', marginTop: 20}}>
                <Button
                  type="button"
                  className="btn-record"
                  data-target=".bd-example-modal-lg"
                  onClick={() => {
                    dispatch(setCurrentTest(null));
                    onLastQuestion();
                    console.log('inside next question');
                  }}>
                  <span>Done</span>
                </Button>
              </div>
            )
          )}
        </div>
        <div
          className="col-xs-12 col-sm-6 col-md-6 col-lg-6 table_recording"
          style={{display: 'inline-block'}}>
          {/* <h3
            onClick={() => {
              // @ts-ignore
              audioRef?.current?.play();
            }}
            className="correct_answer"
            style={{cursor: 'pointer'}}>
            <img src={Record} /> correct answer
          </h3>{' '} */}
          {/*@ts-ignore*/}
          <audio ref={audioRef} src={wordUrl} style={{display: 'none'}} />
          <table className="table" style={{marginBottom: 0}}>
            <thead>
              <tr>
                {/* <td rowSpan={1}>&nbsp;</td> */}
                <p className={'pronunciation-analysis-text'}>
                  Your pronunciation analysis
                </p>
              </tr>
            </thead>

            <thead>
              <tr>
                <th style={{borderLeft: '0px solid black', fontWeight: 500}}>
                  Syllable
                </th>
                <th style={{fontWeight: 500}}>Phonetics</th>
                <th
                  style={{
                    borderRight: '0px solid black',
                    fontWeight: 500,
                    paddingInline: 10,
                  }}>
                  Score
                </th>
              </tr>
            </thead>
            <tbody>
              <td style={{borderBottom: 'none', borderLeft: 'none'}}>
                {selectedWord?.syllable_score_list?.map(
                  (syllable: any, index: number) => {
                    return (
                      <tr
                        key={index}
                        className="Syllable syllable-tr"
                        style={{
                          borderBottomWidth:
                            selectedWord?.syllable_score_list.length - 1 ===
                            index
                              ? 0
                              : '1px',
                        }}>
                        {syllable.letters}
                      </tr>
                    );
                  },
                )}
              </td>
              <td style={{borderBottom: 'none', borderLeft: 'none'}}>
                {selectedWord?.phone_score_list?.map(
                  (phone: any, index: number) => {
                    return (
                      <tr
                        key={index}
                        className="Syllable"
                        style={{
                          width: '100%',
                          height: '100%',
                          borderBottomWidth:
                            selectedWord?.phone_score_list.length - 1 === index
                              ? 0
                              : '1px',
                        }}>
                        {phone.phone}
                      </tr>
                    );
                  },
                )}
              </td>
              <td style={{borderBottom: 'none', borderRight: 'none'}}>
                {selectedWord?.phone_score_list?.map(
                  (phone: any, index: number) => {
                    return (
                      <tr
                        key={index}
                        style={{
                          textAlign: 'center',
                          color:
                            phone?.quality_score > 90 ? '#49CF57' : '#D40032',
                          borderBottomWidth:
                            selectedWord?.phone_score_list.length - 1 === index
                              ? 0
                              : '1px',
                          width: '100%',
                          height: '100%',
                        }}
                        className="Syllable score-tr">
                        {phone?.quality_score > 90
                          ? 'Good'
                          : phone.sound_most_like
                          ? 'Sound like: ' + phone.sound_most_like
                          : 'Missing'}
                      </tr>
                    );
                  },
                )}
              </td>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
