import {isLoadingRequest} from './appState';
import {
  SET_PACKAGE_DETAILS,
  SET_PROGRAMS,
  SET_SELECTED_ENROLLMENT,
  SET_SELECTED_PROGRAM,
} from '../../constants';
import {ProgramService} from '../../lib/services/ProgramService';
import {Enrollment, Package, Program, Student} from '../../lib';

const programService = new ProgramService();

export const doSetPrograms = () => {
  return async (dispatch: any, getState: any) => {
    dispatch(isLoadingRequest(true));
    console.log('programs');
    try {
      let program = await programService.getPrograms();
      console.log('programs', program);
      dispatch({type: SET_PROGRAMS, payload: program});
      dispatch(isLoadingRequest(false));
    } catch (error: any) {
      dispatch(isLoadingRequest(false, error));
      console.log('doSetPrograms catch error', error);
    }
  };
};
export const fetchProgramsDetails = () => {
  return async (dispatch: any, getState: any) => {
    //dispatch(isLoadingRequest(true));
    try {
      let programsDetails = await programService.getProgramsDetails();
      dispatch({type: SET_PROGRAMS, payload: programsDetails});
      // dispatch(isLoadingRequest(false));
    } catch (error: any) {
      dispatch(isLoadingRequest(false, error));
    }
  };
};

export const fetchPackageDetails = (id: string) => {
  return async (dispatch: any, getState: any) => {
    dispatch(isLoadingRequest(true));
    try {
      let packageDetails: Package = await programService.getPackageById(id);
      dispatch(setPackageDetails(packageDetails));
      dispatch(isLoadingRequest(false));
    } catch (error: any) {
      dispatch(isLoadingRequest(false, error));
    }
  };
};

export const setSelectedProgram = (program: Program) => {
  return {type: SET_SELECTED_PROGRAM, payload: program};
};

export const setPackageDetails = (packageDetails: Package) => {
  return {type: SET_PACKAGE_DETAILS, payload: packageDetails};
};
